import "./App.less";
import "./index.css";
import { Route, Routes } from "react-router";
import DomainContentViews from "screens/domain/ContentViews";
import ContentViews from "screens/content/ContentViews";
import AdminContentViews from "screens/admin/ContentViews";
import DomainWidgets from "screens/domain/Widgets";
import DomainPlugit from "screens/domain/PlugitRealtime";
import DomainEventLogs from "screens/domain/EventLogs";
import DomainTriggerTexts from "screens/domain/TriggerTexts";
import "services/fontawesome";
import DomainSaveContents from "screens/domain/SaveContents";
import DomainPlugContents from "screens/domain/PlugContents";
import AdminOverview from "screens/admin/Overview";
import AdminMarketing from "screens/admin/Marketing";
import AdminTechOverview from "screens/admin/TechOverview";
import AdminSearch from "screens/admin/Search";
import AdminSaveContents from "screens/admin/SaveContents";
import AdminPlugContents from "screens/admin/PlugContents";
import AdminAudiences from "screens/admin/Audiences";
import AdminPageViews from "screens/admin/PageViews";
import AdminEmailCampaigns from "screens/admin/EmailCampaigns";
import EmailCampaign from "screens/content/EmailCampaign";
import { useLayoutEffect, useMemo, useState } from "react";
import Layout from "layouts/DefaultLayout";
import RealtimeLayout from "layouts/RealtimeLayout";
import useQueryString from "hooks/useQueryString";
import {
  PARAM_API_KEY,
  PARAM_IS_ADMIN,
  PARAM_PRINT,
  PARAM_PK,
  PARAM_TABLE_ROWS_LIMIT,
} from "constants/queryStringParams";
import AppContextProvider from "contexts/AppContextProvider";
import OverviewWrapper from "screens/domain/Overview/OverviewWrapper";
import EmailCampaignSendInBlue from "screens/content/EmailCampaignSendInBlue";
import { FILTER_CUSTOM } from "components/DateFilter/dateFilterTypes";
import {
  getAdminDefaultDateAvailableReport,
  getDefaultContentDateAvailableReport,
} from "constants/default";
import BulkContentViews from "screens/content/BulkContentViews";
import moment from "moment";
import TriggerTexts from "screens/domain/TriggerTexts/TriggerTexts";
import { useParams } from "react-router";
import { Space } from "antd";

function SingleTriggerText() {
  const { text: filterTriggerText } = useParams();

  return (
    <Layout
      title={
        <Space>
          <span>Trigger Text</span>
          <span className="text-sky-500">{filterTriggerText}</span>
        </Space>
      }
    >
      <TriggerTexts />
    </Layout>
  );
}

const isDev = process.env.NODE_ENV === "development";
// process.env.REACT_APP_PLUGILO_WEB_HOST
function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(isDev);
  const {
    [PARAM_API_KEY]: apiKey,
    [PARAM_PRINT]: print,
    [PARAM_TABLE_ROWS_LIMIT]: tableRowsLimit,
    [PARAM_IS_ADMIN]: isAdmin,
    [PARAM_PK]: pk,
  } = useQueryString();

  useLayoutEffect(() => {
    if (apiKey) {
      window.token = `apiKey ${apiKey}`;
      window.usingApiKey = true;
      setIsAuthenticated(true);
      return;
    }

    if (pk) {
      setIsAuthenticated(true);
      return;
    }
    if (window.parent !== window) {
      window.parent.postMessage({ action: "REQUEST_TOKEN" }, "*");
    }

    const onMessage = (e) => {
      if (e.data?.action === "RESPONSE_TOKEN") {
        window.token = e.data?.token;
        if (window.token) setIsAuthenticated(true);
      }
    };

    window.addEventListener("message", onMessage, false);
    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, []);

  const appContext = useMemo(() => {
    return {
      isPrintMode: print === "1" || print === "true",
      isAdmin: isAdmin === "1" || isAdmin === "true",
      tableRowsLimit: Number(tableRowsLimit) || 10,
    };
  }, [print, tableRowsLimit, isAdmin]);

  if (!isAuthenticated) return null;

  return (
    <AppContextProvider value={appContext}>
      <Routes>
        <Route
          path="bulk-content-views"
          element={
            <Layout
              title="Content Views"
              initialFilterType={FILTER_CUSTOM}
              disableDateAfter={
                isAdmin ? getAdminDefaultDateAvailableReport() : moment()
              }
              warningText="This detailed report is only available after 12 days of publication."
            >
              <BulkContentViews />
            </Layout>
          }
        />
        <Route path="content/:id">
          <Route
            path="content-views"
            element={
              <Layout
                title="Content Views"
                initialFilterType={FILTER_CUSTOM}
                disableDateAfter={
                  isAdmin ? getAdminDefaultDateAvailableReport() : moment()
                }
                warningText="This detailed report is only available after 12 days of publication."
              >
                <ContentViews />
              </Layout>
            }
          />

          <Route
            path="email"
            element={
              <Layout title="Email" dateRangeEnabled={false}>
                <EmailCampaign />
              </Layout>
            }
          />
          <Route
            path="email-sib"
            element={
              <Layout
                title="Email Campaign"
                dateRangeEnabled={false}
                warningEnabled={false}
              >
                <EmailCampaignSendInBlue />
              </Layout>
            }
          />
        </Route>

        <Route path="admin">
          <Route
            path="overview"
            element={
              <Layout title="Overview">
                <AdminOverview />
              </Layout>
            }
          />
          <Route
            path="tech-overview"
            element={
              <Layout title="Tech Overview">
                <AdminTechOverview />
              </Layout>
            }
          />
          <Route
            path="page-views"
            element={
              <Layout title="Page Views">
                <AdminPageViews />
              </Layout>
            }
          />
          <Route
            path="search"
            element={
              <Layout title="Search">
                <AdminSearch />
              </Layout>
            }
          />
          <Route
            path="content-views"
            element={
              <Layout title="Content Views">
                <AdminContentViews />
              </Layout>
            }
          />
          <Route
            path="email-campaigns"
            element={
              <Layout title="Email Campaigns">
                <AdminEmailCampaigns />
              </Layout>
            }
          />
          <Route
            path="save-contents"
            element={
              <Layout title="Save Contents">
                <AdminSaveContents />
              </Layout>
            }
          />
          <Route
            path="plug-contents"
            element={
              <Layout title="Plug Contents">
                <AdminPlugContents />
              </Layout>
            }
          />
          <Route
            path="audiences"
            element={
              <Layout title="Audiences">
                <AdminAudiences />
              </Layout>
            }
          />
          <Route
            path="marketing"
            element={
              <Layout title="Marketing">
                <AdminMarketing />
              </Layout>
            }
          />
        </Route>

        <Route path="domain/:id">
          <Route
            path="overview"
            element={
              <Layout title="Overview">
                <OverviewWrapper />
              </Layout>
            }
          />
          <Route
            path="content-views"
            element={
              <Layout title="Content Views">
                <DomainContentViews />
              </Layout>
            }
          />
          <Route
            path="widgets"
            element={
              <Layout title="Widgets">
                <DomainWidgets />
              </Layout>
            }
          />
          <Route
            path="plugit"
            element={
              <RealtimeLayout title="Plugit" subtitle="Real-time dashboard">
                <DomainPlugit />
              </RealtimeLayout>
            }
          />
            <Route
            path="eventlogs"
            element={
              <RealtimeLayout title="Event Logs" showLastUpdated={false} subTitle={'Real-time event logs'} lookbackWindowOptions={[30,60, 180,360]} >
                <DomainEventLogs showSession={false} />
              </RealtimeLayout>
            }
          />
          <Route
            path="trigger-texts"
            element={
              <Layout title="Trigger texts">
                <DomainTriggerTexts />
              </Layout>
            }
          />
          <Route
            path="save-contents"
            element={
              <Layout title="Save Contents">
                <DomainSaveContents />
              </Layout>
            }
          />
          <Route
            path="plug-contents"
            element={
              <Layout title="Plug Contents">
                <DomainPlugContents />
              </Layout>
            }
          />
        </Route>
            
        <Route path="trigger-text/:text" element={<SingleTriggerText />} />
      
      </Routes>
    </AppContextProvider>
  );
}

export default App;
