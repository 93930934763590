import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  METRIC_EVENT_COUNT,
  METRIC_PAGE_VIEWS,
  METRIC_TOTAL_USERS,
} from "constants/metrics";
import {
  DIMENSION_DATE,
  DIMENSION_DEVICETYPE,
  DIMENSION_EVENT_NAME,
  DIMENSION_EVENT_URL,
  DIMENSION_HOSTNAME,
  DIMENSION_HOSTNAME_FULL,
  DIMENSION_SOURCE_MEDIUM,
  DIMENSION_TIMESTAMP,
} from "constants/dimensions";
import {
  DIMENSION_DURATION,
  DIMENSION_INFOBOARD_BRAND,
  DIMENSION_ITEM_BRAND,
  DIMENSION_ITEM_CATEGORY,
  DIMENSION_ITEM_ID,
  DIMENSION_ITEM_NAME,
  DIMENSION_ITEM_SOURCE_ID,
  DIMENSION_ITEM_SOURCE_NAME,
  DIMENSION_ITEM_SOURCE_STREAM_ID,
  DIMENSION_ITEM_SOURCE_STREAM_NAME,
} from "constants/customDimensions";
import { get, isEmpty, isEqual, orderBy, sum, uniqBy } from "lodash";
import { Col, Row, Spin, Select, Divider } from "antd";
import { GRID_GUTTER, HIDE_VISITORS } from "constants/ui";
import {
  getBatchRealtimReports,
  reviseReport,
  reviseTimeSeriesData,
  getContentNames,
  getInfoboards,
  getEmailuploadersByIds,
} from "services/gaService";
import {
  FILTER_ITEM_SOURCE_PLUGIT,
  buildStringFilter,
  buildInListFilter,
} from "services/gaDimensionsService";
import {
  EVENT_MAXIMIZE_ITEM,
  EVENT_MINIMIZE_ITEM,
  EVENT_PLUG_ITEM,
  EVENT_UNPLUG_ITEM,
  EVENT_VIEW_ITEM_CLICKED,
  EVENT_VIEW_ITEM_DETAILS,
  EVENT_VIEW_ITEM_IMPRESSION,
} from "constants/customEvents";
import Favicon from "components/Favicon";
import { useParams } from "react-router";
import { useReportContext } from "components/ReportContext/ReportContextProvider";
import OverviewSection from "./components/OverviewSection";
import TopViewsSection from "./components/TopViewsSection";
import useSetQueryString from "hooks/useSetQueryString";
import {
  PARAM_WIDGET_HOST,
  PARAM_WIDGET_ITEM_OWNER,
  PARAM_WIDGET_SOURCE,
  PARAM_WIDGET_STREAM,
  PARAM_WIDGET_ITEM_ID,
} from "constants/queryStringParams";
import useQueryString from "hooks/useQueryString";
import { useAppContext } from "contexts/AppContextProvider";
import { alertUnknownError } from "services/notificationService";
import { validate } from "uuid";
import PlugitProvider from "./PlugitProvider";
import {
  TAG_BOOKMARK,
  TAG_FACEBOOK,
  TAG_FEED,
  TAG_HIGHLIGHT,
  TAG_INSTAGRAM,
  TAG_LINKEDIN,
  TAG_LISTSET,
  TAG_PINTEREST,
  TAG_PRODUCT,
  TAG_RSS,
  TAG_TWITTER,
  TAG_YOUTUBE,
} from "constants/contentTags";
import { SOURCE_PLUGIT } from "constants/contentSources";

const defaultOrderBys = [
  {
    desc: true,
    metric: {
      metricName: METRIC_EVENT_COUNT,
    },
  },
];

const buildFilters = (widgetIds, filters, notFilters) => {
  const expressions = [FILTER_ITEM_SOURCE_PLUGIT];
  if (widgetIds && widgetIds.length) {
    expressions.push(buildInListFilter(DIMENSION_ITEM_SOURCE_ID, widgetIds));
  }
  expressions.push(...(filters || []));
  return {
    andGroup: {
      expressions: expressions,
      // notExpressions: notFilters,
    },
  };
};

const domainsHaveProductCardRight = [
  "krups.de",
  "sonicwall.com",
  "vnexpress.net",
  "fptplay.vn",
  "bluechip.de",
  "comteam.de",
];
const ignoreHostNames = [];
const Plugit = () => {
  const [setParam, _, removeParam] = useSetQueryString();
  const {
    [PARAM_WIDGET_HOST]: paramWidgetHost,
    [PARAM_WIDGET_SOURCE]: paramWidgetSource,
    [PARAM_WIDGET_STREAM]: paramWidgetStream,
    [PARAM_WIDGET_ITEM_OWNER]: paramWidgetItemOwner,
    [PARAM_WIDGET_ITEM_ID]: paramWidgetItemId,
  } = useQueryString();

  const [filterReports, setFilterReports] = useState([]);
  const [dataReports, setDataReports] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const {
    lookbackWindow,
    autoRefresh,
    setRefreshing,
    refreshing,
    setLastUpdated,
  } = useReportContext();
  console.log("lookbackWindow", lookbackWindow);
  const [selectedSourceId, setSelectedSourceId] = useState(paramWidgetSource);
  const [selectedSourceStreamId, setSelectedSourceStreamId] =
    useState(paramWidgetStream);
  const [selectedHostName, setSelectedHostName] = useState(paramWidgetHost);
  const [selectedHostNameFull, setSelectedHostNameFull] = useState(null);
  const [selectedItemOwner, setSelectedItemOwner] =
    useState(paramWidgetItemOwner);
  const [selectedItemId, setSelectedItemId] = useState(paramWidgetItemId);
  const [availableContents, setAvailableContents] = useState([]);
  const [availableWidgetSources, setAvailableWidgetSources] = useState([]);
  const [widgetSources, setWidgetSources] = useState([]);
  const [topContentAddedToStackReportData, setTopContentAddedToStackReportData] = useState([]);
  const { id: domain } = useParams();
  const hasProductRight = domainsHaveProductCardRight.includes(domain);
  const autoRefreshTimerRef = useRef(null);
  useEffect(() => {
    const loadData = async () => {
      const result = await getInfoboards(domain);
      const sources = orderBy(
        result.map((x) => ({
          id: x.id,
          name: x.name,
          domain: x.domain,
        })),
        ["label"]
      );
      setWidgetSources(sources);
    };

    loadData();
  }, [domain]);

  const filterReportRequests = useMemo(() => {
    if (!lookbackWindow) return;
    const commonFilters = [];
    if(domain)
      commonFilters.push(buildStringFilter(DIMENSION_INFOBOARD_BRAND, domain));
    return [
      // 0: General Filters
      {
        lookbackWindow,
        dimensions: [
          {
            name: DIMENSION_ITEM_SOURCE_ID,
          },
          {
            name: DIMENSION_ITEM_SOURCE_NAME,
          },
          {
            name: DIMENSION_ITEM_SOURCE_STREAM_ID,
          },
          {
            name: DIMENSION_ITEM_SOURCE_STREAM_NAME,
          },
          {
            name: DIMENSION_HOSTNAME,
          },
          {
            name: DIMENSION_ITEM_BRAND,
          },
          {
            name: DIMENSION_ITEM_ID,
          },
        ],

        dimensionFilter: buildFilters([],commonFilters),
      },
    ];
  }, [domain, lookbackWindow]);

  const dataReportRequests = useMemo(() => {
    if (!lookbackWindow) return;

    const commonFilters = [];

    if (selectedSourceId) {
      commonFilters.push(
        buildStringFilter(DIMENSION_ITEM_SOURCE_ID, selectedSourceId)
      );
    }

    if (selectedSourceStreamId)
      commonFilters.push(
        buildStringFilter(
          DIMENSION_ITEM_SOURCE_STREAM_ID,
          selectedSourceStreamId
        )
      );

    if (selectedHostNameFull)
      commonFilters.push(
        buildStringFilter(DIMENSION_HOSTNAME, selectedHostNameFull)
      );

    if (selectedItemOwner)
      commonFilters.push(
        buildStringFilter(DIMENSION_ITEM_BRAND, selectedItemOwner)
      );
    if (selectedItemId)
      commonFilters.push(buildStringFilter(DIMENSION_ITEM_ID, selectedItemId));
    if(domain)
      commonFilters.push(buildStringFilter(DIMENSION_INFOBOARD_BRAND, domain));

    return [
      // 0: Audiences
      {
        lookbackWindow,
        metrics: [
          {
            name: METRIC_TOTAL_USERS,
          },
          {
            name: METRIC_PAGE_VIEWS,
          },
        ],
        dimensionFilter: buildFilters([], [...commonFilters]),
      },
      // 1: Content statistics Overview
      {
        lookbackWindow,
        dimensions: [
          {
            name: DIMENSION_ITEM_CATEGORY,
          },
          {
            name: DIMENSION_EVENT_NAME,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters(
          [],
          [
            ...commonFilters,
            buildInListFilter(DIMENSION_ITEM_CATEGORY, [
              TAG_BOOKMARK,
              TAG_HIGHLIGHT,
              TAG_PRODUCT,
              TAG_LISTSET,
              TAG_RSS,
              TAG_FEED,
              TAG_TWITTER,
              TAG_FACEBOOK,
              TAG_YOUTUBE,
              TAG_LINKEDIN,
              TAG_PINTEREST,
              TAG_INSTAGRAM,
              SOURCE_PLUGIT,
            ]),
            buildInListFilter(DIMENSION_EVENT_NAME, [
              EVENT_VIEW_ITEM_IMPRESSION,
              EVENT_VIEW_ITEM_DETAILS,
              EVENT_VIEW_ITEM_CLICKED,
              EVENT_PLUG_ITEM,
              EVENT_UNPLUG_ITEM,
              EVENT_MINIMIZE_ITEM,
              EVENT_MAXIMIZE_ITEM,
              EVENT_PLUG_ITEM,
              EVENT_UNPLUG_ITEM,
            ]),
          ]
        ),
      },
      // 2: Plugit Avg Overview
      {
        lookbackWindow,
        dimensions: [
          {
            name: DIMENSION_ITEM_SOURCE_ID,
          },
          {
            name: DIMENSION_EVENT_NAME,
          },
          {
            name: DIMENSION_DURATION,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters(
          [],
          [
            ...commonFilters,
            buildInListFilter(DIMENSION_ITEM_CATEGORY, [SOURCE_PLUGIT]),
            buildInListFilter(DIMENSION_EVENT_NAME, [EVENT_MINIMIZE_ITEM]),
          ]
        ),
      },
      // 3: Visitor Overtime
      {
        lookbackWindow,
        dimensions: [
          {
            name: DIMENSION_TIMESTAMP,
            transformation_id: "to_start_of_minute",
          },
        ],
        metrics: [
          {
            name: METRIC_TOTAL_USERS,
          },
        ],
        dimensionFilter: buildFilters([], [...commonFilters]),
      },
      // 4: Device Types Overview
      {
        lookbackWindow,
        dimensions: [
          {
            name: DIMENSION_DEVICETYPE,
          },
        ],
        metrics: [
          {
            name: METRIC_TOTAL_USERS,
          },
        ],
        dimensionFilter: buildFilters([], [...commonFilters]),
      },
      // 5: Top pages
      {
        lookbackWindow,
        dimensions: [
          {
            name: DIMENSION_EVENT_URL,
            transformation_id: "strip_qs",
          },
        ],
        metrics: [
          {
            name: METRIC_PAGE_VIEWS,
          },
          {
            name: METRIC_TOTAL_USERS,
          },
        ],
        dimensionFilter: buildFilters([], [...commonFilters]),
        order_by: [
          [
              1,
              "desc"
          ]
      ],
      limit:10
      },
      // 6: Top content added to stack
      {
        lookbackWindow,
        dimensions: [
          {
            name: DIMENSION_ITEM_ID,
          },        
        ],
        metrics: [         
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters([], [...commonFilters, buildInListFilter(DIMENSION_EVENT_NAME, [EVENT_PLUG_ITEM])]),
        order_by: [
          [
              1,
              "desc"
          ]
      ],
      limit:10
      },
      // 7: Traffic sources
      {
        lookbackWindow,
        dimensions: [
          {
            name: DIMENSION_SOURCE_MEDIUM,
          },        
        ],
        metrics: [         
          {
            name: METRIC_TOTAL_USERS,
          },
        ],
        dimensionFilter: buildFilters([], [...commonFilters,]),
        order_by: [
          [
              1,
              "desc"
          ]
      ],
      },
    ];
  }, [lookbackWindow, selectedSourceId, selectedSourceStreamId, selectedHostNameFull, selectedItemOwner, selectedItemId, domain]);

  

  const resetData = () => {
    setFilterReports([]);
    setDataReports([]);
  };
  const loadFilterReports = useCallback(async () => {
    if (!filterReportRequests) return;
    // setIsLoading(true);
    try {
      const data = await getBatchRealtimReports(filterReportRequests);
      setFilterReports(get(data, "reports", []));
    } catch (error) {
      alertUnknownError();
      resetData();
    } finally {
      // setIsLoading(false);
    }
  }, [filterReportRequests]);

  useEffect(() => {
    loadFilterReports();
  }, [loadFilterReports]);

  const loadDataReports = useCallback(
    async (auto_refresh) => {
      if (!dataReportRequests) return;

      setRefreshing(true);
      if (!auto_refresh) setIsLoading2(true);
      try {
        let data = await getBatchRealtimReports(dataReportRequests);
        data = get(data, "reports", []); 
        if(JSON.stringify(dataReports)!==JSON.stringify(data)){
        // if(!isEqual(dataReports, data)){
          setDataReports(data);
          setLastUpdated(new Date());
        }
      } catch (error) {
        alertUnknownError();
        resetData();
      } finally {
        setRefreshing(false);
        if (!auto_refresh) setIsLoading2(false);
      }
    },
    [dataReportRequests, dataReports,]
  );

  

  useEffect(() => {
    loadDataReports();
  }, []);

  useEffect(() => {
    // if(!autoRefreshTimerRef?.current) return;
    autoRefreshTimerRef.current = setInterval(() => {
      if (refreshing) return;

      loadFilterReports();
      loadDataReports(true);
    }, autoRefresh * 1000);
    return () => clearInterval(autoRefreshTimerRef.current);
  }, [autoRefresh, loadDataReports, loadFilterReports, refreshing]);

  const widgetFilter = useCallback(
    (report, otherFilter) => {
      if (isEmpty(report)) return {};

      const rows = report.rows.filter((row) => {
        const matchWidgetSourceId = selectedSourceId
          ? row[DIMENSION_ITEM_SOURCE_ID] === selectedSourceId
          : true;
        const matchHostName = selectedHostName
          ? row[DIMENSION_HOSTNAME] === selectedHostName
          : true;
        const matchIgnoreHostNames = !ignoreHostNames.includes(
          row[DIMENSION_HOSTNAME]
        );
        const matchOtherFilter = otherFilter ? otherFilter(row) : true;

        return (
          matchWidgetSourceId &&
          matchHostName &&
          matchIgnoreHostNames &&
          matchOtherFilter
        );
      });

      return {
        ...report,
        rows,
      };
    },
    [selectedHostName, selectedSourceId]
  );

  const commonFilter = useCallback(
    (report, otherFilter) => {
      if (isEmpty(report)) return {};

      const rows = report.rows.filter((row) => {
        const matchWidgetSourceId = selectedSourceId
          ? row[DIMENSION_ITEM_SOURCE_ID] === selectedSourceId
          : true;
        const matchWidgetSourceStreamId = selectedSourceStreamId
          ? row[DIMENSION_ITEM_SOURCE_STREAM_ID] === selectedSourceStreamId
          : true;
        const matchHostName = selectedHostName
          ? row[DIMENSION_HOSTNAME] === selectedHostName
          : true;
        const matchItemOwner = selectedItemOwner
          ? row[DIMENSION_ITEM_BRAND] === selectedItemOwner
          : true;
        const matchItemId = selectedItemId
          ? row[DIMENSION_ITEM_ID] === selectedItemId
          : true;

        const matchIgnoreHostNames = !ignoreHostNames.includes(
          row[DIMENSION_HOSTNAME]
        );
        const matchOtherFilter = otherFilter ? otherFilter(row) : true;

        return (
          matchWidgetSourceId &&
          matchWidgetSourceStreamId &&
          matchHostName &&
          matchIgnoreHostNames &&
          matchOtherFilter &&
          matchItemOwner &&
          matchItemId
        );
      });

      return {
        ...report,
        rows,
      };
    },
    [
      selectedHostName,
      selectedItemOwner,
      selectedSourceId,
      selectedSourceStreamId,
      selectedItemId,
    ]
  );

  const filtersReportData = useMemo(() => {
    const report = reviseReport(filterReports[0]);
    if (!isEmpty(report)) {
      return {
        ...report,
        rows: report.rows.filter(
          (row) => !ignoreHostNames.includes(row[DIMENSION_HOSTNAME])
        ),
      };
    }

    return report;
  }, [filterReports]);


  useEffect(() => {
    if (!filtersReportData.rowCount) return setAvailableWidgetSources([]);
    const loadData = async () => {
      const infoboardIds = uniqBy(
        filtersReportData.rows.map((x) => x[DIMENSION_ITEM_SOURCE_ID])
      );
      const result = widgetSources.filter((it) => infoboardIds.includes(it.id));
      const sources = orderBy(
        result.map((x) => ({
          value: x.id,
          label: x.name,
          domain: x.domain,
        })),
        ["label"]
      );
      setAvailableWidgetSources(sources);
    };

    loadData();
  }, [filtersReportData, widgetSources]);

  const widgetImpressionsReportData = useMemo(() => {
    return widgetFilter(reviseReport(filterReports[1]));
  }, [widgetFilter, filterReports]);

  const OverviewReportData = useMemo(() => {
    return reviseReport(dataReports[0]);
  }, [dataReports]);
  // console.log('OverviewReportData', OverviewReportData);

  const contentViewsReportData = useMemo(() => {
    const revisedReport = reviseReport(dataReports[1]);
    return revisedReport;
  }, [dataReports]);
  // console.log('contentViewsReportData', contentViewsReportData);

  const plugitOverviewReportData = useMemo(() => {
    const revisedReport = reviseReport(dataReports[2]);
    return revisedReport;
  }, [dataReports]);
  // console.log('plugitOverviewReportData', plugitOverviewReportData);

  const visitorOvertimeReportData = useMemo(() => {
    const revisedReport = reviseReport(dataReports[3]);
    return revisedReport;
  }, [dataReports]);
  // console.log("visitorOvertimeReportData", visitorOvertimeReportData);

  const deviceTypesReportData = useMemo(() => {
    const revisedReport = reviseReport(dataReports[4]);
    return revisedReport;
  }, [dataReports]);
  // console.log("deviceTypesReportData", deviceTypesReportData);

  const topPageViewsReportData = useMemo(() => {
    const revisedReport = reviseReport(dataReports[5]);
    return get(revisedReport, "rows", []);
  }, [dataReports]);
  // console.log("topPageViewsReportData", topPageViewsReportData);

  const topContentAddedIds = useMemo(() => {
    const revisedReport = reviseReport(dataReports[6]);
    return get(revisedReport, "rows", []).map(row=> row[DIMENSION_ITEM_ID]);
  }, [dataReports]);

  const trafficSourcesReportData = useMemo(() => {
    const revisedReport = reviseReport(dataReports[7]);
    return get(revisedReport, "rows", []);
  }, [dataReports]);

  const topContentAddedDataReportRequest = useMemo(() => {
    if (!lookbackWindow || isEmpty(topContentAddedIds)) return;
    return [      
      // 0: Top content added to stack
      {
        lookbackWindow,
        dimensions: [
          {
            name: DIMENSION_ITEM_ID,
          },
          {
            name: DIMENSION_ITEM_NAME,
          },
          {
            name: DIMENSION_ITEM_BRAND,
          },
          {
            name: DIMENSION_ITEM_CATEGORY,
          },
          {
            name: DIMENSION_EVENT_NAME,
          },
        ],
        metrics: [         
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters([],           
          [
            buildInListFilter(DIMENSION_EVENT_NAME, [EVENT_PLUG_ITEM, EVENT_VIEW_ITEM_IMPRESSION, EVENT_VIEW_ITEM_DETAILS, EVENT_VIEW_ITEM_CLICKED]),
            buildInListFilter(DIMENSION_ITEM_ID, topContentAddedIds)
        ]),      
      },
    ];
  }, [
    lookbackWindow,
    topContentAddedIds
  ]);
  // load top data content added to stack
  useEffect(() => {
    // topContentAddedDataReportRequest
    const loadTopContentAddedStats = async ()=> {
      try {
        if(topContentAddedDataReportRequest){
          const result = await getBatchRealtimReports(topContentAddedDataReportRequest);
          const data = reviseReport(get(result, 'reports[0]'));
          // console.log('setTopContentAddedToStackReportData', data);
          setTopContentAddedToStackReportData(get(data, "rows",[]));
        }else{
          setTopContentAddedToStackReportData([]);
        }
      } catch (error) {       
      
      } 
    };
    loadTopContentAddedStats();
   
  }, [topContentAddedDataReportRequest]);

  const usersReportData = useMemo(() => {
    return reviseReport(dataReports[1]);
  }, [dataReports]);

  const contentActiveViewsReportData = useMemo(() => {
    return reviseReport(dataReports[2]);
  }, [dataReports]);

  const contentClicksReportData = useMemo(() => {
    return reviseReport(dataReports[3]);
  }, [dataReports]);

  const availablePartners = useMemo(() => {
    if (!filtersReportData.rowCount) return [];

    return uniqBy(
      filtersReportData.rows.map((row) => ({
        [DIMENSION_HOSTNAME]: row[DIMENSION_HOSTNAME],
        [DIMENSION_HOSTNAME_FULL]: row[DIMENSION_HOSTNAME_FULL],
      })),
      DIMENSION_HOSTNAME
    );
  }, [filtersReportData]);

  const availableItemOwners = useMemo(() => {
    if (!filtersReportData.rowCount) return [];

    return uniqBy(
      filtersReportData.rows.map((row) => ({
        [DIMENSION_ITEM_BRAND]: row[DIMENSION_ITEM_BRAND],
      })),
      DIMENSION_ITEM_BRAND
    );
  }, [filtersReportData]);

  const contentViewsTimeSeriesData = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return [];

    const rowsGroupedByDate = contentViewsReportData.rows.reduce(
      (result, current) => {
        const date = current[DIMENSION_DATE];
        if (!result[date])
          result[date] = {
            [EVENT_VIEW_ITEM_DETAILS]: 0,
            [EVENT_VIEW_ITEM_IMPRESSION]: 0,
          };
        result[date].date = date;

        if (current[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_IMPRESSION)
          result[date][EVENT_VIEW_ITEM_IMPRESSION] = sum([
            result[date][EVENT_VIEW_ITEM_IMPRESSION],
            current[METRIC_EVENT_COUNT],
          ]);
        if (current[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_DETAILS)
          result[date][EVENT_VIEW_ITEM_DETAILS] = sum([
            result[date][EVENT_VIEW_ITEM_DETAILS],
            current[METRIC_EVENT_COUNT],
          ]);

        return result;
      },
      {}
    );

    // return orderBy(Object.values(rowsGroupedByDate), [DIMENSION_DATE]);
    return reviseTimeSeriesData(Object.values(rowsGroupedByDate), {
      dateKey: DIMENSION_DATE,
      dataKeys: [EVENT_VIEW_ITEM_IMPRESSION, EVENT_VIEW_ITEM_DETAILS],
    });
  }, [contentViewsReportData]);

  // Content Impressions

  const onSourceChange = useCallback(
    (value) => {
      setSelectedSourceId(value);
      setParam(PARAM_WIDGET_SOURCE, value);
      setSelectedSourceStreamId(null);
      removeParam(PARAM_WIDGET_STREAM);
    },
    [removeParam, setParam]
  );

  const onSourceStreamChange = useCallback(
    (value) => {
      setSelectedSourceStreamId(value);
      setParam(PARAM_WIDGET_STREAM, value);
    },
    [setParam]
  );

  const onHostChange = useCallback(
    (value, option) => {
      setSelectedHostName(value);
      setSelectedHostNameFull(option[DIMENSION_HOSTNAME_FULL]);
      setParam(PARAM_WIDGET_HOST, value);
    },
    [setParam]
  );

  const onItemOwnerChange = useCallback(
    (value, option) => {
      setSelectedItemOwner(value);
      setParam(PARAM_WIDGET_ITEM_OWNER, value);
    },
    [setParam]
  );

  const onContentChange = useCallback(
    (value, option) => {
      setSelectedItemId(value);
      setParam(PARAM_WIDGET_ITEM_ID, value);
    },
    [setParam]
  );

  const adImpressionsEnabled =
    !selectedSourceStreamId && !selectedItemOwner && !selectedItemId;
  const context = useMemo(() => {
    return {
      clicksEnabled: hasProductRight,
      adImpressionsEnabled,
    };
  }, [hasProductRight, adImpressionsEnabled]);

  return (
    <PlugitProvider value={context}>
      <Spin spinning={isLoading || isLoading2}>
        <Row gutter={GRID_GUTTER} className="xl:justify-left">
          <Col xs={12} lg={6} xl={4} className="mb-8">
            <Select
              value={selectedSourceId}
              onChange={onSourceChange}
              className="w-full"
              defaultValue={null}
            >
              <Select.Option value={null}>All widgets</Select.Option>
              {availableWidgetSources.map((widget) => (
                <Select.Option value={widget.value} key={widget.value}>
                  {widget.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>

        <OverviewSection
          domain={domain}
          lookbackWindow={lookbackWindow}
          widgetImpressionsReportData={widgetImpressionsReportData}
          OverviewReportData={OverviewReportData}
          contentViewsReportData={contentViewsReportData}
          plugitOverviewReportData={plugitOverviewReportData}
          usersReportData={usersReportData}
          contentViewsTimeSeriesData={contentViewsTimeSeriesData}
          visitorOvertimeReportData={visitorOvertimeReportData}
          deviceTypesReportData={deviceTypesReportData}
        />
        <Divider />

        <TopViewsSection
          topContentAddedToStackReportData={topContentAddedToStackReportData}
          topPageViewsReportData={topPageViewsReportData}
          trafficSourcesReportData={trafficSourcesReportData}
        />
      </Spin>
    </PlugitProvider>
  );
};

Plugit.propTypes = {};

export default Plugit;
