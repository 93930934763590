import {
  Alert,
  Button,
  Card,
  Col,
  Collapse,
  Empty,
  Form,
  message,
  Pagination,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useMemo } from "react";
import { Radio, Input } from "antd";
import { useState } from "react";
import { GRID_GUTTER } from "constants/ui";
import { publicIpv4 } from "public-ip";
import { getEventDebugger } from "services/gaService";
import { get, isNil, omit, omitBy, set } from "lodash";
import { useReportContext } from "components/ReportContext/ReportContextProvider";
import moment from "moment";
import { formatDuration } from "services/numberService";
import Icon from "components/Icon";
import EventTimeline from "./components/EventTimeline";
import produce from "immer";
import { EVENT_ALL_EVENTS } from "constants/customEvents";
import { DIMENSION_EVENT_ACTION } from "constants/customDimensions.piwik";

const EventLogs = ({showSession}) => {
  const [form] = Form.useForm();
  const [myIP, setMyIP] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchFilter, setSearchFilter] = useState({});
  const [pageInfo, setPageInfo] = useState({ offset: 0, limit: 20 });
  const [sessions, setSessions] = useState([]);
  const primaryFilter = Form.useWatch("primaryFilter", form);
  const { lookbackWindow } = useReportContext();
  const [expandedKeys, setExpandedKeys] = useState([]);

  const flattenEvents = useMemo(() => {
    return sessions.reduce((acc, session) => {
      return [...acc, ...session.events.map(event => ({ ...event, session: omit(session, ['events']) }))];
    }, []).sort((a, b) => moment(b.server_time).diff(moment(a.server_time)));
  }, [sessions]);

  const hasNextPage = useMemo(() => {
    return sessions.length > pageInfo.limit;
  }, [pageInfo.limit, sessions.length]);

  const hasPreviousPage = useMemo(() => {
    return pageInfo.offset > 0;
  }, [pageInfo.offset]);

  const currentPage = useMemo(() => {
    return Math.floor(pageInfo.offset / pageInfo.limit) + 1;
  }, [pageInfo.limit, pageInfo.offset]);

  const goToPreviousPage = useCallback(() => {
    setPageInfo((p) =>
      produce(p, (draft) => {
        draft.offset = Math.max(0, draft.offset - draft.limit);
      })
    );
  }, []);
  const goToNextPage = useCallback(() => {
    setPageInfo((p) =>
      produce(p, (draft) => {
        draft.offset += draft.limit;
      })
    );
  }, []);

  useEffect(() => {
    const getIp = async () => {
      const ip = await publicIpv4();
      form.setFieldsValue({ localIP: ip });
      setMyIP(ip);
    };
    getIp();
  }, [form]);

  const validateIPv4 = (ip) => {
    const ipv4Pattern =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return ipv4Pattern.test(ip);
  };

  useEffect(() => {
    setSearchFilter((s) =>
      produce(s, (draft) => {
        draft.lookup_window = lookbackWindow;
      })
    );
  }, [lookbackWindow]);

  const refineSessions = (sessions) => {
    return sessions.map((session) => {
      const events = session.events
        .filter((event) => get(event, "event_type[0]") === 5 && EVENT_ALL_EVENTS.includes(event[DIMENSION_EVENT_ACTION]))
        .sort((a, b) => moment(b.server_time).diff(moment(a.server_time)));
      return {
        ...session,
        events,
      };
    }).filter((session) => session.events.length > 0);
  };

  const fetchEventDebugger = useCallback(async () => {
    try {
      setIsLoading(true);
      const result = await getEventDebugger(
        omitBy(
          {
            ...searchFilter,
            offset: pageInfo.offset,
            limit: pageInfo.limit + 1,
          },
          isNil
        )
      );
      const sessions = refineSessions(get(result, "result", []));
      setSessions(sessions);
    } catch (e) {
      message.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  }, [searchFilter, pageInfo]);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchEventDebugger();
    }, 300); // 300ms debounce time

    return () => {
      clearTimeout(handler);
    };
  }, [fetchEventDebugger]);

  const handleFormSubmit = useCallback(
    async (values) => {
      const payload = {
        lookup_window: lookbackWindow,
        event_type: 5, // for custom event
      };
      if (values.primaryFilter === "localIP" && values.localIP) {
        payload.ip = values.localIP;
      } else {
        payload.ip = null;
      }
      if (values.primaryFilter === "pageURL" && values.pageURL) {
        payload.page_url = values.pageURL;
      } else {
        payload.page_url = null;
      }
      if (values.searchFilter) {
        switch (values.secondaryFilter) {
          case "ip":
            payload.ip = values.searchFilter;
            break;
          case "page_url":
            payload.page_url = values.searchFilter;
            break;
          case "visitor_id":
            payload.visitor_id = values.searchFilter;
            break;
          case "user_id":
            payload.user_id = values.searchFilter;
            break;
          default:
            break;
        }
      }
      if (payload.ip) {
        // masking ip address
        const ipParts = payload.ip.split(".");
        ipParts[3] = "0";
        payload.ip = ipParts.join(".");
      }
      setSearchFilter(payload);

      // await fetchEventDebugger();
    },
    [lookbackWindow]
  );

  const secondaryFilterOptions = useMemo(() => {
    const options = [
      { value: "ip", label: "IP" },
      { value: "page_url", label: "Page URL" },
      { value: "visitor_id", label: "Visitor ID" },
      { value: "user_id", label: "User ID" },
    ];
    if (primaryFilter)
      return options.filter(
        (item) =>
          (primaryFilter === "localIP" && item.value !== "ip") ||
          (primaryFilter === "pageURL" && item.value !== "page_url")
      );
    return options;
  }, [primaryFilter]);

  const handleFilterByVisitorID = (visitorId) => {
    form.setFieldsValue({
      secondaryFilter: "visitor_id",
      searchFilter: visitorId,
    });
  };

  const getSessionInfo = (session) => {
    const userInfo = (
      <>
        <Icon name="user" />
        <Typography.Text>Annonymous</Typography.Text>
      </>
    );
    const parts = [];

    if (session.location_ipv4) {
      parts.push(`IP: ${session.location_ipv4}`);
    }
    if (session.browser_name && session.browser_name.length > 1) {
      parts.push(`${session.browser_name[1]} ${session.browser_version}`);
    }
    if (session.operating_system && session.operating_system.length > 1) {
      let s = `${session.operating_system[1]}`;
      if (session.device_type && session.operating_system.length > 1) {
        s += ` ${session.device_type[1]}`;
      }
      parts.push(s);
    }
    if (session.location_city_name) {
      parts.push(session.location_city_name);
    }
    if (session.location_country_name) {
      parts.push(session.location_country_name);
    }
    return (
      <>
        {userInfo}
        {parts.length ? `(${parts.join(", ")})` : ""}
      </>
    );
  };

  return (
    <Space direction="vertical" className="w-full" size={1}>
      <Alert
        type="info"
        message="The Event Logs shows sessions from the last six hours."
        showIcon
      ></Alert>
      <Form
        form={form}
        onFinish={handleFormSubmit}
        initialValues={{
          primaryFilter: "localIP",
          secondaryFilter: "visitor_id",
        }}
      >
        <Row gutter={GRID_GUTTER} className="xl:justify-left mt-2">
          <Col xs={12} lg={6} xl={4}>
            <Form.Item name="primaryFilter">
              <Radio.Group>
                <Radio value="localIP">Local IP</Radio>
                <Radio value="pageURL" style={{ marginLeft: 20 }}>
                  Page URL
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={12} lg={18} xl={20}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.primaryFilter !== currentValues.primaryFilter
              }
            >
              {({ getFieldValue }) => (
                <>
                  {getFieldValue("primaryFilter") === "localIP" && (
                    <>
                      <Form.Item
                        name="localIP"
                        rules={[
                          {
                            validator: (_, value) =>
                              validateIPv4(value)
                                ? Promise.resolve()
                                : Promise.reject("Invalid IP"),
                          },
                        ]}
                      >
                        <Input placeholder="Enter IP" />
                      </Form.Item>
                    </>
                  )}
                  {getFieldValue("primaryFilter") === "pageURL" && (
                    <Form.Item
                      name="pageURL"
                      rules={[
                        {
                          required: true,
                          message: "Please enter a URL",
                        },
                      ]}
                    >
                      <Input placeholder="Enter URL" />
                    </Form.Item>
                  )}
                </>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={GRID_GUTTER} className="xl:justify-left">
          <Col xs={12} lg={6} xl={4} className="mb-8">
            <Form.Item name="secondaryFilter">
              <Select className="w-full">
                {secondaryFilterOptions.map((item) => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={12} lg={18} xl={20}>
            <Row gutter={8}>
              <Col flex="auto">
                <Form.Item name="searchFilter">
                  <Input placeholder="Search" allowClear />
                </Form.Item>
              </Col>
              <Col flex={showSession? '200px': '100px'}>
                <Space size={4} className="justify-end w-full">
                  <Button htmlType="submit">
                    Refresh
                  </Button>
                  {showSession && <>                  
                    <Button
                      type="secondary"
                      onClick={() =>
                        setExpandedKeys(sessions.map((s) => s.session_id))
                      }
                    >
                      Expand All
                    </Button>
                    <Button type="secondary" onClick={() => setExpandedKeys([])}>
                      Collapse All
                    </Button>
                  </>}
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      <Spin spinning={isLoading}>
        {showSession && <>
        {!sessions.length && !isLoading && (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
        {!!sessions.length && (
          <Collapse
            activeKey={expandedKeys}
            onChange={(e) => setExpandedKeys(e)}
          >
            {sessions.slice(0, pageInfo.limit).map((session) => (
              <Collapse.Panel
                header={
                  <Row className="w-full">
                    <Col flex={3}>
                      <Typography.Text strong>
                        {moment(session.server_time).format(
                          "dddd, MMMM D, YYYY [at] h:mm A"
                        )}
                      </Typography.Text>
                    </Col>
                    <Col flex={2}>
                      <div className="text-right">
                        <Space>
                          <Typography.Text>
                            {session.events.length}{" "}
                            {session.events.length > 1 ? "events" : "event"}
                          </Typography.Text>
                          {!!session.session_total_time && (
                            <Typography.Text>
                              {" "}
                              - {formatDuration(session.session_total_time)}
                            </Typography.Text>
                          )}
                          <Button
                            icon={<Icon name="crosshairs" />}
                            size="small"
                            shape="circle"
                            onClick={(e) => {
                              handleFilterByVisitorID(session.visitor_id_hex);
                              e.stopPropagation();
                            }}
                          ></Button>
                        </Space>
                      </div>
                    </Col>
                  </Row>
                }
                key={session.session_id}
              >
                <Card
                  size="small"
                  title={<Space>{getSessionInfo(session)}</Space>}
                >
                  <EventTimeline events={session.events} />
                </Card>
              </Collapse.Panel>
            ))}
          </Collapse>
        )}
        {(hasNextPage || hasPreviousPage) && (
          <Space className="mt-4">
            <Button
              disabled={!hasPreviousPage}
              size="small"
              onClick={goToPreviousPage}
              icon={<Icon name="arrow-left" />}
            ></Button>
            <Typography.Text>{currentPage}</Typography.Text>
            <Button
              disabled={!hasNextPage}
              size="small"
              onClick={goToNextPage}
              icon={<Icon name="arrow-right" />}
            ></Button>
          </Space>
        )}</>}
        {!showSession && <>
          {!!flattenEvents.length && <EventTimeline events={flattenEvents} />}
          {!flattenEvents.length &&   <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </> }
        
      </Spin>
    </Space>
  );
};
EventLogs.defaultProps = {
  showSession: true,
};
export default EventLogs;
