import PropTypes from "prop-types";
import React, {  useMemo, useState } from "react";
import { Affix, Layout, Space, Typography } from "antd";
import classNames from "classnames";
import Icon from "components/Icon";
import ReportContextProvider from "components/ReportContext/ReportContextProvider";
import useSetQueryString from "hooks/useSetQueryString";
import LookbackWindowFilter from "components/LookbackWindowFilter";
import LastUpdated from "./components/LastUpdated";
import { get } from "lodash";

const DefaultLayout = ({
  title,
  subTitle,
  children,
  lookbackWindowEnabled,
  showLastUpdated,
  lookbackWindowOptions,
}) => {
  const [affixed, setAffixed] = useState();
  const [_, setParamBatch] = useSetQueryString();
  const [lookbackWindow, setLookbackWindow] = useState(get(lookbackWindowOptions, '[0]', 5));
  const [refreshing, setRefreshing] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(new Date());

  const contextValue = useMemo(() => {
    return {
      lookbackWindow,
      dateStrings: ['2021-01-01', '2021-01-02'],
      autoRefresh: 5,
      refreshing,
      setRefreshing,
      lastUpdated,
      setLastUpdated
    };
  }, [lookbackWindow, refreshing]);

  return (
    <Layout>
      <Layout.Content className="bg-white">
        <Affix offsetTop={0} onChange={setAffixed} className="print:hidden">
          <div
            className={classNames(
              "bg-white duration-300 ease-in-out transition-shadow flex items-center bg-opacity-95",
              { shadow: affixed }
            )}
          >
            <div className="container px-4 py-2 flex flex-wrap sm:flex-nowrap items-center">
              <div className="flex sm:flex-col lg:w-auto w-full mb-1 sm:mb-0">
                <Typography.Title level={5} style={{ marginBottom: "2px" }}>
                  {title}
                </Typography.Title>
                {lookbackWindowEnabled && subTitle && (
                  <div className="text-gray-400 text-xxs ml-auto sm:ml-0">
                    {subTitle}
                  </div>
                )}
              </div>

              <div className="flex sm:flex-nowrap items-start ml-auto sm:flex-shrink-0">
                {lookbackWindowEnabled && (
                  <Space direction="horizontal">                    
                      {showLastUpdated && <Space> <LastUpdated lastUpdated={lastUpdated} /></Space> }                    
                    <Icon name="timer" />
                    Lookback window:
                    <LookbackWindowFilter options={lookbackWindowOptions} onChange={(v)=> setLookbackWindow(v)}/>
                  </Space>
                )}
              
              </div>
            </div>
          </div>
        </Affix>
        <div className="hidden print:flex items-center mb-2">
          <Typography.Title level={5} style={{ marginBottom: "2px" }}>
            {title}
          </Typography.Title>
          {lookbackWindowEnabled && (
            <div className="text-gray-400 text-xxs ml-auto">
              {lookbackWindow}
            </div>
          )}
        </div>
        <hr className="m-0 border-gray-100" />
        <div className="container p-4">
          <ReportContextProvider value={contextValue}>
            {children}
          </ReportContextProvider>
        </div>
      </Layout.Content>
    </Layout>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.any,
  title: PropTypes.any,
  lookbackWindowEnabled: PropTypes.bool,
  showLastUpdated: PropTypes.bool,
};

DefaultLayout.defaultProps = {
  lookbackWindowEnabled: true,
  showLastUpdated: true,
};

export default DefaultLayout;
